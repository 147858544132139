/*
 * @Author: zhengda.lv
 * @Date: 2021-10-18 11:32:22
 * @LastEditTime: 2021-10-21 14:57:09
 * @LastEditors: zhengda.lv
 * @Description:
 */
export interface SelectFormat {
    value: string;
    title: string;
}

export enum RequestTypeListEnum {
    'Delay' = 1,
    'Callback',
}

export const StatusListMap = {
    Submitted: '1',
    'Region Approved': '2',
    'Region Reject': '3',
    'SIPO Approved': '4',
    'SIPO Reject': '5',
};
export const StatusListMap2 = {
    '1': 'Submitted',
    '2': 'Region Approved',
    '3': 'Region Reject',
    '4': 'SIPO Approved',
    '5': 'SIPO Reject',
    '6': 'System Reject',
    '7': 'System Reject'
};

export enum ProgramTypeListEnum {
    '保险支持' = 1,
    '售后支持',
    '精品',
}

export interface ProgramType {
    id: string;
    pathKey: string;
    nameEn: string;
    nameCn: string;
}
