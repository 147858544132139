import { fetchGet, fetchPost } from '../index';

//! 获取概览数据
const getOverviewValue = (params: any): Promise<any> => {
    return fetchPost('/claimapi/delay/region/count', params);
};

//! search 查询接口
const searchData = (params: any): Promise<any> => {
    return fetchPost('/claimapi/delay/region/search', params);
};

//! approve 接口
const approve = (params: any): Promise<any> => {
    return fetchPost('/claimapi/delay/region/approve', params);
};

//! send 接口
const reject = (params: any): Promise<any> => {
    return fetchPost('/claimapi/delay/region/reject', params);
};

//! 获取claim detial详细数据
const getClaimDetail = (id: string): Promise<any> => {
    return fetchGet(`/claimapi/delay/region/detailList/${id}`);
};

//! detail页提交
const submitClaim = (params: any): Promise<any> => {
    return fetchPost(`/claimapi/delay/region/detailSubmit`, params);
};

//! 下载文件附件
const getDocument = (params: any): Promise<any> => {
    return fetchGet('/claimapi/delay/apply/downloadFile', params);
};

export default {
    getOverviewValue,
    searchData,
    approve,
    reject,
    getClaimDetail,
    submitClaim,
    getDocument,
};
